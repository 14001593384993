import { components, dictionary as DICTIONARY_COMPONENTS } from '@/mixins/components'
import qs from 'qs'
import Axios from 'axios'

const base = document.querySelector('base')
const axios = Axios.create({
	baseURL: `${base.getAttribute('href')}/api`
})

const loadPage = async (slug = null, type = null, routeName = 'Pagina') => {
	let params = { type: type || null }
	const route = (routeName) => {
		switch (routeName) {
			case 'Noticia': return `noticias/${slug}`
			case 'Servico': return `external-service/${slug}`
			case 'Servicos': return `paginas/servicos?${qs.stringify(params)}`
			case 'Busca': return `paginas/busca?${qs.stringify(params)}`
			default: return `paginas${slug ? '/' + slug : ''}?${qs.stringify(params)}`
		}
	}
	const response = await axios.get(route(routeName))
	if (response.status > 400)
		return Promise.reject()
	return response.data
}

const searchInNode = (search, node) => {
	for (let item of node.children) {
		const regexDelete = /<span class="searched highlight">([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]+)<\/span>/gm;
		item.innerHTML = item.innerHTML.replace(regexDelete, '$1');
		if ((!item.children.length && item.nodeName != 'A') && item.textContent.toUpperCase().includes(search.toUpperCase())) {
			const regexInsert = new RegExp(`(${search})`, `gmi`)
			item.innerHTML = item.innerHTML.replace(regexInsert, `<span class="searched highlight">$1</span>`)
		} else {
			searchInNode(search, item)
		}
	}
}

export default {
	components,
	data() {
		return {
			pagina: {
				content: [],
			},
		}
	},
	metaInfo() {
		return {
			title: this.seo.title || this.pagina.title || '',
			meta: this.meta
		}
	},
	computed: {
		type() {
			return this.pagina.type || null
		},
		specialPage() {
			if (this.type == 'timeline')
				return this.pagina.timeLineType
			if (this.isOrganograma) {
				if (this.isOrganogramaPlano)
					return this.type + '-plano'
				return this.type + '-organizacional'
			}
			return null
		},
		content() {
			return this.pagina.content || []
		},
		seo() {
			return this.pagina.seo || {}
		},
		meta() {
			const keys = Object.keys(this.seo).filter(key => key !== 'title')
			return keys.map(key => {
				return { name: key, vmid: key, content: this.seo[key] }
			})
		},
		mappedContents() {
			if (!Array.isArray(this.content))
				return []
			if (this.specialPage && this.specialPage != 'organograma-organizacional') {
				if (this.content.find(item => item.type == 'banner') != undefined) {
					let banner = this.content.find(item => item.type == 'banner')
					let contents = this.content.slice(1)
					return [
						{ component: this.$options.components.Banner, contents: banner, type: 'banner', special: null },
						{ component: this.parentComponent(), contents, type: this.type, special: this.specialPage }
					]
				}
				return [
					{ component: this.parentComponent(), contents: this.content, type: this.type, special: this.specialPage }
				]
			}
			let contents = this.content.map(item => {
				return Object.assign({}, { component: this.$options.components[DICTIONARY_COMPONENTS[item.type]], contents: item, type: item.type, special: null })
			})
			// if (this.specialPage == 'organograma-organizacional')
			//     contents.push({ component: this.parentComponent(this.specialPage), contents: [], type: this.type, special: this.specialPage })
			return contents
		},
		isOrganograma() {
			return this.type == 'organograma'
		},
		isOrganogramaPlano() {
			if (!Array.isArray(this.content))
				return false
			return this.content.find(item => item.type == 'organograma') != undefined
		},
		hasBanner() {
			return this.mappedContents.find(item => item.type == 'banner') != undefined
		},
	},
	watch: {
		'$store.state.searchInPage': function () {
			this.searchInPage()
		}
	},
	beforeRouteEnter(to, from, next) {
		if (to.params.slug == 'noticias')
			return next({ name: 'Noticias' })
		if (to.params.slug == 'servicos')
			return next({ name: 'Servicos' })
		if (to.params.slug == 'busca')
			return next({ name: 'Busca' })
		if (to.params.slug == 'o-ippuc' || to.params.slug == 'institucional')
			return next({ name: 'Institucional' })
		loadPage(to.params.slug, to.meta.type, to.name)
			.then(response => {
				next(vm => {
					vm.$store.commit('toggleMenuAtivo', false)
					vm.setPage(response)
					vm.$store.dispatch('toggleLoader')
				})
			})
			.catch(() => {
				next({ name: 'Home' })
			})
	},
	beforeRouteUpdate(to, from, next) {
		this.$store.dispatch('toggleLoader')
		this.$store.commit('toggleMenuAtivo', false)
		if (to.params.slug == 'noticias')
			return next({ name: 'Noticias' })
		if (to.params.slug == 'servicos')
			return next({ name: 'Servicos' })
		if (to.params.slug == 'busca')
			return next({ name: 'Busca' })
		if (to.params.slug == 'o-ippuc' || to.params.slug == 'institucional')
			return next({ name: 'Institucional' })
		this.resetPage()
		loadPage(to.params.slug, to.meta.type, to.name)
			.then(response => {
				this.setPage(response)
				this.$store.dispatch('toggleLoader')
				next()
			})
			.catch(() => {
				next({ name: 'Home' })
			})
	},
	methods: {
		resetPage() {
			this.$store.commit('setSearchInPage', '')
			this.setPage({ contents: [] })
		},
		setPage(page) {
			this.$set(this, 'pagina', page)
		},
		parentComponent(type = null) {
			type = type || this.type
			switch (type) {
				case 'timeline': return this.$options.components.Timeline
				case 'organograma': return this.$options.components.Organograma
				case 'organograma-organizacional': return this.$options.components.OrganogramaOrganizacional
				default: return null
			}
		},
		searchInPage() {
			let search = this.$store.state.searchInPage
			if (this.$refs.pagina) {
				searchInNode(search, this.$refs.pagina)
			} else {
				this.$router.push({ name: 'Busca', query: { search } })
			}
		}
	},
}
